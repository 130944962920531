// In a separate file to facilitate mocking env vars in jest

import { type Locale } from "@10xdev/language-utils";

export const getPrefix = () => {
  const prefix =
    process.env.ALGOLIA_INDEX_PREFIX ||
    process.env.VERCEL_GIT_COMMIT_REF ||
    process.env.GIT_BRANCH_NAME ||
    process.env.GITHUB_HEAD_REF ||
    "dev";

  // use one common index for dependabot PRs,
  // since they are many and don't really change much
  // regarding algolia
  if (prefix.startsWith("dependabot")) {
    return "dependabot";
  }

  if (prefix.startsWith("cms")) {
    return "cms";
  }

  return prefix;
};

export const sanitizeIndexName = (name: string) => {
  // https://www.algolia.com/doc/faq/index-configuration/what-can-i-name-my-indices/
  const forbiddenRegex = /(\/|~|,|\\|`|&|\||;|\$|\*)/g;

  const encodedIndexName = encodeURIComponent(name);

  if (forbiddenRegex.test(encodedIndexName)) {
    throw new Error(`${name} cannot be encoded safely`);
  }

  return encodedIndexName;
};

export class IndexName {
  baseName: string;

  constructor(baseName: string) {
    this.baseName = baseName;
  }

  toString() {
    const indexNamespace = getPrefix();
    return `${sanitizeIndexName(indexNamespace)}:${this.baseName}`;
  }
}

export const analysisGuidesIndexName = new IndexName("analysis-guides");
export const blogPostsIndexName = new IndexName("blog_posts");
export const compatibleProductsIndexName = new IndexName("compatible_products");
export const datasetsIndexName = new IndexName("datasets");
export const distributorsIndexName = new IndexName("distributors");
export const documentsIndexName = new IndexName("documents");
export const eventsIndexName = new IndexName("events");
export const instrumentUserGuidesIndexName = new IndexName(
  "instrument_user_guides",
);
export const jobsIndexName = new IndexName("jobs");
export const newsArticlesIndexName = new IndexName("news_articles");
export const pagesIndexName = new IndexName("pages");
export const publicationsIndexName = new IndexName("publications");
export const searchDocumentsIndexName = new IndexName("search-documents");
export const serviceProvidersIndexRefreshKey = new IndexName(
  "service-providers",
);
export const serviceProvidersEnIndexName = new IndexName(
  "service-providers-en",
);
export const serviceProvidersZhIndexName = new IndexName(
  "service-providers-cn",
);
export const serviceProvidersJaIndexName = new IndexName(
  "service-providers-jp",
);
export const softwarePagesIndexName = new IndexName("software-pages");
export const supportDocumentsIndexName = new IndexName("support-documents");
export const supportPagesIndexName = new IndexName("support-pages");
export const userGuidesIndexName = new IndexName("user_guides");
export const videosIndexName = new IndexName("videos");

export const serviceProvidersLocaleToIndexName = {
  cn: serviceProvidersZhIndexName,
  en: serviceProvidersEnIndexName,
  jp: serviceProvidersJaIndexName,
} satisfies Partial<Record<Locale, IndexName>>;

export const indexNames = {
  analysisGuidesIndexName,
  blogPostsIndexName,
  compatibleProductsIndexName,
  datasetsIndexName,
  distributorsIndexName,
  documentsIndexName,
  eventsIndexName,
  newsArticlesIndexName,
  pagesIndexName,
  publicationsIndexName,
  searchDocumentsIndexName,
  serviceProvidersEnIndexName,
  serviceProvidersJaIndexName,
  serviceProvidersZhIndexName,
  softwarePagesIndexName,
  supportDocumentsIndexName,
  supportPagesIndexName,
  userGuidesIndexName,
  videosIndexName,
};

export const getIndexNames = () => ({
  newestFirst: publicationsIndexName,
  oldestFirst: `${publicationsIndexName}-oldest-first`,
  relevance: `${publicationsIndexName}-relevance`,
});
