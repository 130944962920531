import { css } from "@emotion/react";
import type { Property } from "csstype";
import type { ReactNode } from "react";

import {
  BaseLayoutProps,
  baseStylePropKeys,
  generateResponsiveCSS,
  separateStyleProps,
  WithResponsive,
} from "@10xdev/utils";

export interface FlexItemProps extends BaseLayoutProps {
  as?: keyof JSX.IntrinsicElements;
  children?: ReactNode;
  className?: string;
  flex?: WithResponsive<Property.Flex>;
  flexGrow?: WithResponsive<Property.FlexGrow>;
  flexShrink?: WithResponsive<Property.FlexShrink>;
  flexBasis?: WithResponsive<Property.FlexBasis>;
}

function FlexItem({
  as: Component = "div",
  children,
  className,
  ...props
}: FlexItemProps) {
  const flexItemStylePropKeys = [
    ...baseStylePropKeys,
    "flex",
    "flexGrow",
    "flexShrink",
    "flexBasis",
  ];
  const { styles, rest } = separateStyleProps(props, flexItemStylePropKeys);
  const cssString = generateResponsiveCSS({ ...styles });
  return (
    <Component
      className={className}
      css={css`
        ${cssString}
      `}
      {...rest}
    >
      {children}
    </Component>
  );
}

export default FlexItem;
