import { colorSteelLighter, colorWhite } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { forwardRef } from "react";

import MenuListItem from "./MenuListItem";
import ProductMenuPanel from "./ProductMenuPanel";
import { ProductMenuItemType } from "../types";
import { MenuKeydownHandlers } from "./useMenuKeydownHandlers";
import { isProductMenuItem } from "../utils";
import { useMenuNavigation } from "./useMenuNavigation";
import Flex from "../../Flex";
import MenuArrow from "./MenuArrow";

const menuContainerStyles = css`
  background-color: ${colorWhite};
  border-radius: 16px;
  width: 1029px;
  border: 1px solid ${colorSteelLighter};
  overflow: hidden;
  height: 415px;
`;

interface ProductMenuProps extends MenuKeydownHandlers {
  handleClose: () => void;
  menuItems: ProductMenuItemType[];
}

const ProductMenu = forwardRef<HTMLDivElement, ProductMenuProps>(
  (
    {
      handleClose,
      menuItems,
      onEscapeKeyDown,
      onShiftTabKeyDown,
      onTabKeyDown,
    },
    ref,
  ) => {
    const {
      submenuRefs,
      submenuLinkRefs,
      activeMenuItemId,
      activeMenuItemIndex,
      activeMenuItem,
      handleKeyDown,
      handleMenuItemHover,
      handleMenuItemLeave,
    } = useMenuNavigation({
      menuItems,
      onEscapeKeyDown,
      onShiftTabKeyDown,
      onTabKeyDown,
    });

    return (
      <div
        css={menuContainerStyles}
        onKeyDown={(event) => handleKeyDown(event, activeMenuItemIndex)}
        ref={ref}
      >
        <Flex
          alignItems="stretch"
          css={css`
            height: 100%;
          `}
        >
          <Flex
            flexDirection="column"
            css={css`
              flex: 0 0 342px;
              padding: 1rem 0;
            `}
            role="list"
          >
            {menuItems.map((item, index) => (
              <MenuListItem
                key={item.id}
                active={item.id === activeMenuItemId}
                description={item.description}
                handleClose={handleClose}
                link={item.link}
                onHover={() => handleMenuItemHover(item.id)}
                onMouseLeave={() => {
                  handleMenuItemLeave();
                  submenuRefs.current[index]?.blur();
                }}
                ref={(el) => el && (submenuRefs.current[index] = el)}
                showHighlightStroke={isProductMenuItem(item)}
                title={item.title}
                titleIcon={item.stickAtBottom ? "arrow-right" : undefined}
                css={
                  item.stickAtBottom
                    ? css`
                        margin-top: auto;
                      `
                    : undefined
                }
              />
            ))}
          </Flex>

          <Flex
            alignItems="stretch"
            css={css`
              flex: 1;
              position: relative;
            `}
          >
            {menuItems.map((item) => (
              <ProductMenuPanel
                key={item.id}
                css={css`
                  display: ${item.id === activeMenuItemId ? "flex" : "none"};
                `}
                menuId={item.id}
                linkRefs={submenuLinkRefs}
                panel={item.panel}
                handleClose={handleClose}
              />
            ))}

            {activeMenuItem && isProductMenuItem(activeMenuItem) && (
              <MenuArrow
                topPosition={activeMenuItem.panel.arrowPosition ?? "0"}
              />
            )}
          </Flex>
        </Flex>
      </div>
    );
  },
);

export default ProductMenu;
