import { css } from "@emotion/react";
import type { Property } from "csstype";
import type { ReactNode } from "react";

import {
  BaseLayoutProps,
  baseStylePropKeys,
  generateResponsiveCSS,
  separateStyleProps,
  WithResponsive,
} from "@10xdev/utils";

export interface FlexProps extends BaseLayoutProps {
  alignItems?: WithResponsive<Property.AlignItems>;
  as?: keyof JSX.IntrinsicElements;
  children?: ReactNode;
  className?: string;
  flex?: WithResponsive<Property.Flex>;
  flexDirection?: WithResponsive<Property.FlexDirection>;
  flexWrap?: WithResponsive<Property.FlexWrap>;
  gap?: WithResponsive<Property.Gap>;
  justifyContent?: WithResponsive<Property.JustifyContent>;
  justifySelf?: WithResponsive<Property.JustifySelf>;
}

function Flex({
  as: Component = "div",
  children,
  className,
  ...props
}: FlexProps) {
  const flexStylePropKeys = [
    ...baseStylePropKeys,
    "alignItems",
    "flex",
    "flexDirection",
    "flexWrap",
    "gap",
    "justifyContent",
    "justifySelf",
  ];
  const { styles, rest } = separateStyleProps(props, flexStylePropKeys);
  const cssString = generateResponsiveCSS({ ...styles });

  return (
    <Component
      className={className}
      css={css`
        display: flex;
        ${cssString}
      `}
      {...rest}
    >
      {children}
    </Component>
  );
}

export default Flex;
