import {
  colorBlueMedium,
  colorSteelLighter,
  colorWhite,
  layoutBannerHeight,
  sizeXxxlarge,
} from "@10xdev/design-tokens";
import { css, keyframes } from "@emotion/react";
import chroma from "chroma-js";
import { useRouter } from "next/router";
import type { FunctionComponent } from "react";
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import FocusLock from "react-focus-lock";
import { RemoveScroll } from "react-remove-scroll";
import { useKey } from "react-use";

import Icon from "../../Icon";
import IconButton from "../../IconButton";
import ThematicBreak from "../../ThematicBreak";
import MobileNavItems from "./MobileNavItems";
import NavSections from "./NavSections";
import { Menu, MenuAdditionalLink, MenuItem, NavigationType } from "../types";
import {
  isLinkMenu,
  isLinksPanel,
  isMenuAdditionalLink,
  isProductMenuItem,
} from "../utils";
import NavLinksSection from "./NavLinksSection";

interface Props {
  data: NavigationType;
  onClose: () => void;
  onNavigate: () => void;
}

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const slideIn = keyframes`
  0% { left: 100%; }
  100% { left: ${sizeXxxlarge}; }
`;

const containerStyles = css`
  left: 0;
  min-height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
`;

const overlayStyles = css`
  animation: ${fadeIn} 0.25s;
  background-color: ${chroma(colorBlueMedium).alpha(0.9).hex()};
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

const menuStyles = css`
  animation: ${slideIn} 0.25s;
  background-color: ${colorWhite};
  bottom: 0;
  left: ${sizeXxxlarge};
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  right: 0;
  top: 0;
  padding: 2rem 1.5rem 1.5rem;
  z-index: 9999;
`;

const headerStyles = css`
  align-items: center;
  border-bottom: 1px solid ${colorSteelLighter};
  display: flex;
  height: ${layoutBannerHeight};
  justify-content: space-between;
  position: fixed;
  top: 0;
  background: ${colorWhite};
  width: 100%;
  z-index: 9999;
`;

const contentStyles = css`
  display: flex;
  flex-direction: column;
`;

const MobileNavMenu: FunctionComponent<Props> = ({
  data,
  onClose,
  onNavigate,
}) => {
  const router = useRouter();
  const [selectedItem, setSelectedItem] = useState<Menu | null>(null);
  const [selectedSectionItem, setSelectedSectionItem] =
    useState<MenuItem | null>(null);
  const targetRef = useRef<HTMLDivElement>(null);
  const [targetMounted, setTargetMounted] = useState(false);
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setTargetMounted(Boolean(targetRef.current));
    if (closeButtonRef.current) {
      closeButtonRef.current.focus();
    }
  }, []);

  useKey("Escape", onClose);

  const handleBack = () => {
    if (selectedSectionItem) {
      setSelectedSectionItem(null);
    } else if (selectedItem) {
      setSelectedItem(null);
    }
  };
  const handleItemClick = (item: Menu | MenuAdditionalLink) => {
    if (isLinkMenu(item) || isMenuAdditionalLink(item)) {
      router.push(item.url);
    } else {
      setSelectedItem(item);
    }
  };

  const handleSectionItemClick = (item: MenuItem) => {
    if (isProductMenuItem(item)) {
      if (isLinksPanel(item.panel)) {
        setSelectedSectionItem(item);
      } else {
        if (item.link) {
          router.push(item.link);
        }
      }
    }
  };

  const portal = targetMounted
    ? createPortal(
        <FocusLock returnFocus>
          <RemoveScroll>
            <div css={overlayStyles} tabIndex={-1} />
            <div css={menuStyles}>
              <div css={headerStyles}>
                {selectedItem ? (
                  <IconButton onClick={handleBack}>
                    <Icon color="blue" size="18px" source="nav-left" />
                  </IconButton>
                ) : (
                  <IconButton onClick={onClose} ref={closeButtonRef}>
                    <Icon color="blue" size="18px" source="close" />
                  </IconButton>
                )}
              </div>
              <ThematicBreak />
              <div css={contentStyles}>
                {!selectedItem && data.menu && (
                  <MobileNavItems data={data} onClick={handleItemClick} />
                )}
                {selectedItem &&
                  !selectedSectionItem &&
                  !isLinkMenu(selectedItem) && (
                    <NavSections
                      direction="column"
                      items={selectedItem.items}
                      onNavigate={onNavigate}
                      onSectionItemClick={handleSectionItemClick}
                    />
                  )}
                {selectedSectionItem &&
                  isProductMenuItem(selectedSectionItem) &&
                  isLinksPanel(selectedSectionItem.panel) && (
                    <NavLinksSection
                      links={selectedSectionItem.panel.links}
                      onNavigate={onNavigate}
                      title={selectedSectionItem.title}
                      featureAnnouncement={
                        selectedSectionItem.panel.featureAnnouncement
                      }
                    />
                  )}
              </div>
            </div>
          </RemoveScroll>
        </FocusLock>,
        targetRef.current!,
      )
    : null;

  return (
    <>
      <div css={containerStyles} ref={targetRef} role="dialog" tabIndex={-1} />
      {portal}
    </>
  );
};

export default MobileNavMenu;
