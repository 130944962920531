import { css } from "@emotion/react";
import type { Property } from "csstype";
import type { ReactNode } from "react";

import {
  BaseLayoutProps,
  baseStylePropKeys,
  generateResponsiveCSS,
  separateStyleProps,
  WithResponsive,
} from "@10xdev/utils";

export interface GridProps extends BaseLayoutProps {
  alignContent?: WithResponsive<Property.AlignContent>;
  alignItems?: WithResponsive<Property.AlignItems>;
  as?: keyof JSX.IntrinsicElements;
  children?: ReactNode;
  className?: string;
  gap?: WithResponsive<Property.Gap>;
  gridAutoColumns?: WithResponsive<Property.GridAutoColumns>;
  gridAutoFlow?: WithResponsive<Property.GridAutoFlow>;
  gridAutoRows?: WithResponsive<Property.GridAutoRows>;
  gridTemplateColumns?: WithResponsive<Property.GridTemplateColumns>;
  gridTemplateRows?: WithResponsive<Property.GridTemplateRows>;
  justifyContent?: WithResponsive<Property.JustifyContent>;
  justifyItems?: WithResponsive<Property.JustifyItems>;
}
function Grid({
  as: Component = "div",
  children,
  className,
  ...props
}: GridProps) {
  const gridStylePropKeys = [
    ...baseStylePropKeys,
    "alignContent",
    "alignItems",
    "gap",
    "gridAutoColumns",
    "gridAutoFlow",
    "gridAutoRows",
    "gridTemplateColumns",
    "gridTemplateRows",
    "justifyContent",
    "justifyItems",
  ];
  const { styles, rest } = separateStyleProps(props, gridStylePropKeys);
  const cssString = generateResponsiveCSS({ ...styles });
  return (
    <Component
      className={className}
      css={css`
        display: grid;
        ${cssString}
      `}
      {...rest}
    >
      {children}
    </Component>
  );
}

export default Grid;
