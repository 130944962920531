import { useMemo } from "react";
import NavigationPopup from "./NavigationPopup";
import NavigationLink from "./NavigationLink";
import { NavigationType } from "../types";
import { colors } from "../../Text";
import { css } from "@emotion/react";
import { isLinkMenu } from "../utils";
import { mediaLaptop } from "@10xdev/design-tokens";

interface NavigationListProps {
  data: NavigationType["menu"];
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  textColor: keyof typeof colors;
  getNavItemStyle: (isActive: boolean) => any;
  menuRefs: React.MutableRefObject<(HTMLElement | null)[]>;
}

const listStyles = css`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  gap: 1rem;
  height: 3rem;
  list-style: none;
  margin: 0;
  padding: 0;

  @media (min-width: ${mediaLaptop}) {
    gap: 2.5rem;
  }
`;

const NavigationList = ({
  data,
  activeIndex,
  setActiveIndex,
  textColor,
  getNavItemStyle,
  menuRefs,
}: NavigationListProps) => {
  const navigationItems = useMemo(
    () =>
      data.map((navItem, index) =>
        isLinkMenu(navItem) ? (
          <NavigationLink
            key={navItem.url}
            navItem={navItem}
            index={index}
            activeIndex={activeIndex}
            textColor={textColor}
            getNavItemStyle={getNavItemStyle}
            menuRefs={menuRefs}
          />
        ) : (
          <NavigationPopup
            key={navItem.label}
            navItem={navItem}
            index={index}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            textColor={textColor}
            getNavItemStyle={getNavItemStyle}
            menuRefs={menuRefs}
          />
        ),
      ),
    [data, activeIndex, textColor, getNavItemStyle, menuRefs, setActiveIndex],
  );

  return <ul css={listStyles}>{navigationItems}</ul>;
};

export default NavigationList;
