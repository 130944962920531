import { useRef, useState, useMemo, useCallback, useEffect } from "react";
import {
  useMenuKeydownHandlers,
  MenuKeydownHandlers,
} from "./useMenuKeydownHandlers";
import { MenuItem } from "../types";

interface UseMenuNavigationProps extends MenuKeydownHandlers {
  menuItems: MenuItem[];
}

/**
 *  Manages the active menu item and handles keyboard navigation for a menu.
 */
export const useMenuNavigation = ({
  menuItems,
  onEscapeKeyDown,
  onShiftTabKeyDown,
  onTabKeyDown,
}: UseMenuNavigationProps) => {
  const submenuRefs = useRef<HTMLElement[]>([]);
  const submenuLinkRefs = useRef<Record<string, HTMLAnchorElement[]>>({});
  const [activeMenuItemId, setActiveMenuItemId] = useState(menuItems[0].id);

  const [submenuLinks, setSubmenuLinks] = useState<HTMLAnchorElement[]>([]);
  const hoverTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    setSubmenuLinks(submenuLinkRefs.current[activeMenuItemId] ?? []);
  }, [activeMenuItemId]);

  const activeMenuItemIndex = useMemo(
    () => menuItems.findIndex((item) => item.id === activeMenuItemId),
    [activeMenuItemId, menuItems],
  );

  const { handleKeyDown } = useMenuKeydownHandlers({
    submenuLinks,
    submenus: submenuRefs.current,
    onEscapeKeyDown,
    onShiftTabKeyDown,
    onTabKeyDown,
  });

  const handleMenuItemHover = useCallback((id: string) => {
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
    }

    hoverTimeout.current = setTimeout(() => {
      setActiveMenuItemId(id);
    }, 100);
  }, []);

  const handleMenuItemLeave = useCallback(() => {
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
    }
  }, []);

  const activeMenuItem = useMemo(
    () => menuItems.find((item) => item.id === activeMenuItemId),
    [activeMenuItemId, menuItems],
  );

  return {
    submenuRefs,
    submenuLinkRefs,
    activeMenuItemId,
    activeMenuItemIndex,
    activeMenuItem,
    handleKeyDown,
    handleMenuItemHover,
    handleMenuItemLeave,
  };
};
