import { kebabCase } from "lodash-es";
import defaultSlugify from "slugify";

/**
 * it's insanely annoying that there is in fact a function buried deep in
 * the bowels of netlify-cms-core, but it's not really accessible,
 * so we gotta do it ourselves
 * @param {string} val
 * @param {Parameters<typeof import('slugify').default>[1] | undefined=} slugifyOptions
 * @returns
 */
export const slugify = (
  val: string,
  slugifyOptions?: Parameters<typeof defaultSlugify>[1],
) => {
  const withoutPlusses = val.replace(/\+/g, "plus");
  const defaultSlugified = defaultSlugify(withoutPlusses, slugifyOptions);
  const kebabed = kebabCase(defaultSlugified);

  return kebabed.replace(/\./g, "-").toLowerCase();
};

export default slugify;

/**
 * @param {string} version
 * @param {string} slug
 * @returns string
 */
export const getVersionedSoftwareSupportPageSlug = (
  version: string,
  slug: string,
) => {
  const versionSlug = version === "latest" ? "" : slugify(version);
  return versionSlug ? `${slug}-${versionSlug}` : slug;
};
