import { NavigationType } from "../types";

export const createCNNavigationData = (basePath: string): NavigationType => ({
  name: "Site nav",
  menu: [
    {
      type: "productMenu",
      label: "产品",
      items: [
        {
          id: "chromium-single-cell",
          title: "Chromium单细胞分析",
          description:
            "对新鲜、冷冻和FFPE组织中的基因表达进行分析，并提供多组学分析选项",
          panel: {
            type: "links",
            arrowPosition: "48px",
            rows: 3,
            links: [
              {
                id: "chromium-portfolio-overview",
                link: `${basePath}/platforms/chromium`,
                title: "产品组合概览",
                description: "从这里开始您的Chromium单细胞之旅",
              },
              {
                id: "chromium-technology",
                link: `${basePath}/platforms/chromium/technology`,
                title: "技术",
                description: "探索这项准确而可靠的单细胞技术的核心",
              },
              {
                id: "chromium-products",
                link: `${basePath}/platforms/chromium/product-family`,
                title: "产品",
                description: "了解哪种单细胞产品满足您的研究需求",
              },
              {
                id: "chromium-applications",
                link: `${basePath}/platforms/chromium/applications`,
                title: "应用",
                description: "利用附加功能和多组学读数解决各种问题",
              },
              {
                id: "chromium-x-series-instruments",
                link: `${basePath}/instruments/chromium-family`,
                title: "Chromium X系列单细胞仪器",
                description: "探索仪器的功能和自动化微滴生成的优势",
              },
              {
                id: "chromium-support-documentation",
                link: `${basePath}/support`,
                title: "支持文档",
                description: "访问全面的资源，包括用户指南、方案、教程等",
              },
            ],
            image: {
              src: "https://cdn.10xgenomics.com/image/upload/dpr_2.0,f_auto,q_auto/v1727391195/navigation/Chromium_background.png",
              height: 126,
              width: 671,
            },
          },
        },
        {
          id: "visium-spatial",
          title: "Visium空间分析",
          description: "在组织背景下进行全转录组发现",
          panel: {
            type: "links",
            arrowPosition: "148px",
            rows: 2,
            links: [
              {
                id: "visium-portfolio-overview",
                link: `${basePath}/platforms/visium`,
                title: "产品组合概览",
                description: "从这里开始您的Visium空间之旅",
              },
              {
                id: "visium-products",
                link: `${basePath}/platforms/visium#assays`,
                title: "产品",
                description: "了解哪种Visium产品满足您的研究需求",
              },
              {
                id: "visium-instruments",
                link: `${basePath}/instruments/visium-cytassist`,
                title: "仪器",
                description:
                  "看看Visium CytAssist如何简化您的工作流程并扩大您的样本范围",
              },
              {
                id: "visium-support-documentation",
                link: `${basePath}/support`,
                title: "支持文档",
                description: "访问全面的资源，包括用户指南、方案、教程等",
              },
            ],
            image: {
              src: "https://cdn.10xgenomics.com/image/upload/dpr_2.0,f_auto,q_auto/v1727391196/navigation/Visium_background.png",
              height: 240,
              width: 671,
            },
          },
        },
        {
          id: "xenium-in-situ",
          title: "Xenium原位分析",
          description: "通过单细胞空间成像分析组织切片中多达5,000个RNA靶点",
          panel: {
            type: "links",
            arrowPosition: "246px",
            rows: 2,
            links: [
              {
                id: "xenium-portfolio-overview",
                link: `${basePath}/platforms/xenium`,
                title: "产品组合概览",
                description: "从这里开始您的Xenium原位分析之旅",
              },
              {
                id: "xenium-gene-expression-panels",
                link: `${basePath}/products/xenium-panels`,
                title: "Xenium基因表达panel",
                description: "了解哪种Xenium panel满足您的研究需求",
              },
              {
                id: "xenium-instruments",
                link: `${basePath}/instruments/xenium-analyzer`,
                title: "仪器",
                description:
                  "看看Xenium分析仪如何整合高分辨率成像和机载数据分析",
              },
              {
                id: "xenium-support-documentation",
                link: `${basePath}/support/in-situ-gene-expression`,
                title: "支持文档",
                description: "访问全面的资源，包括用户指南、方案、教程等",
              },
            ],
            image: {
              src: "https://cdn.10xgenomics.com/image/upload/dpr_2.0,f_auto,q_auto/v1727391196/navigation/Xenium_background.png",
              height: 240,
              width: 671,
            },
          },
        },
        {
          id: "compare-products",
          panel: {
            type: "info",
            arrowPosition: "405px",
            bulletedList: [
              "兼容的物种和样本",
              "多组学分析能力",
              "通量",
              "分辨率",
              "混样选择",
              "兼容仪器",
            ],
            image: {
              src: "https://cdn.10xgenomics.com/image/upload/dpr_2.0,f_auto,q_auto/v1727391195/navigation/compare_products_background.png",
              height: 220,
              width: 615,
            },
            title: "10x Genomics全部应用比较",
          },
          link: `${basePath}/products`,
          stickAtBottom: true,
          title: "产品比较",
        },
      ],
    },
    {
      type: "resourceMenu",
      label: "资源",
      items: [
        {
          description: "探索由10x Genomics产品提供支持的同行评审出版物",
          icon: "publications",
          id: "publications",
          link: `${basePath}/publications`,
          title: "文献",
        },
        {
          description: "访问使用10x Genomics产品创建的免费、多样化的数据集",
          icon: "datasets",
          id: "datasets",
          link: `${basePath}/datasets`,
          title: "数据集",
        },
        {
          description: "访问所有产品的用户指南、教程和实验方案",
          icon: "documentation",
          id: "support-documentation",
          link: `${basePath}/support`,
          title: "支持文档",
        },
        {
          description: "阅读超越10x软件的分析介绍和教程",
          icon: "analysis-guides-computer",
          id: "analysis-guides",
          link: `${basePath}/analysis-guides`,
          title: "分析指南",
        },
        {
          id: "blog",
          link: `${basePath}/blog`,
          title: "博客",
        },
        {
          id: "videos",
          link: `${basePath}/videos`,
          title: "视频",
        },
        {
          id: "library",
          link: `${basePath}/library`,
          title: "文档",
        },
        {
          id: "software",
          link: `${basePath}/software`,
          title: "软件",
        },
        {
          id: "service-providers",
          link: `${basePath}/service-providers`,
          title: "服务商",
        },
        {
          id: "events",
          link: `${basePath}/events`,
          title: "活动",
        },
      ],
    },
    {
      type: "link",
      label: "支持",
      url: `${basePath}/support`,
    },
    {
      type: "companyMenu",
      label: "公司",
      items: [
        {
          id: "about",
          link: `${basePath}/company`,
          title: "关于10x Genomics",
        },
        {
          id: "team-and-board",
          link: `${basePath}/company#executive-team`,
          title: "团队和董事会",
        },
        {
          id: "careers",
          link: `https://careers.10xgenomics.com/`,
          title: "职业发展",
        },
        {
          id: "investors",
          link: `https://investors.10xgenomics.com/`,
          title: "投资者",
        },
        {
          id: "news",
          link: `${basePath}/news`,
          title: "新闻",
        },
        {
          id: "distributors",
          link: `${basePath}/distributors`,
          title: "经销商",
        },
        {
          id: "contact-us",
          link: `${basePath}/contact`,
          title: "联系我们",
        },
      ],
    },
  ],
  additionalLinks: [
    {
      label: "Store",
      url: `${basePath}/store`,
      target: "_self",
      locale: false,
    },
    {
      label: "Search",
      url: `${basePath}/search`,
      target: "_self",
      hasIcon: true,
    },
  ],
});
