import {
  colorBlueDark,
  colorBlueLightest,
  colorBlueMedium,
  colorWhite,
} from "@10xdev/design-tokens";
import type { IconSource } from "@10xdev/types/models/IconSource";
import { css } from "@emotion/react";
import { forwardRef, useCallback, type ForwardRefRenderFunction } from "react";
import Anchor from "../../Anchor";
import Icon from "../../Icon";
import Text from "../../Text";
import Flex from "../../Flex";
import FlexItem from "../../Flex/FlexItem";

const activeMenuItemStyle = css`
  background-color: ${colorBlueLightest};
  color: ${colorBlueDark};

  &:before {
    display: block;
  }
`;

const getMenuItemStyle = (
  active: boolean,
  showHighlightStroke?: boolean,
) => css`
  background-color: ${colorWhite};
  color: ${colorBlueMedium};
  cursor: pointer;
  position: relative;

  ${showHighlightStroke &&
  `
    &:before {
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      content: "";
      height: 100%;
      width: 0.5rem;
      background: linear-gradient(${colorBlueMedium}, #0073e0);
    }
  `}

  &:focus {
    outline: none;
  }

  ${active && activeMenuItemStyle}
`;

const menuItemPaddingStyle = (compact: boolean) => css`
  padding: ${compact ? "0.75rem 1.5rem" : "1rem 2rem"};
`;

export interface MenuListItemProps {
  active: boolean;
  className?: string;
  compact?: boolean;
  description?: string;
  handleClose: () => void;
  icon?: IconSource;
  link?: string;
  onHover?: () => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLAnchorElement | HTMLDivElement>;
  onMouseLeave?: () => void;
  onTouchStart?: () => void;
  onTouchEnd?: () => void;
  showHighlightStroke?: boolean;
  title: string;
  titleIcon?: IconSource;
}

const MenuListItem: ForwardRefRenderFunction<
  HTMLAnchorElement | HTMLDivElement,
  MenuListItemProps
> = (
  {
    active,
    className,
    compact = false,
    icon,
    link,
    handleClose,
    title,
    titleIcon,
    description,
    onHover,
    onKeyDown,
    onMouseLeave,
    onTouchStart,
    onTouchEnd,
    showHighlightStroke = false,
  },
  ref,
) => {
  const handleClick = useCallback(() => {
    if (!link) {
      return;
    }
    const NAV_CLOSE_DELAY = 100;
    setTimeout(handleClose, NAV_CLOSE_DELAY);
  }, [handleClose, link]);

  const content = (
    <Flex gap="1rem">
      {icon && (
        <FlexItem flex="0 0 32px">
          <Icon color="transparent" size="32px" source={icon} yPos="6px" />
        </FlexItem>
      )}
      <div>
        <Flex
          alignItems="center"
          gap="1rem"
          css={css`
            color: ${active ? colorBlueDark : colorBlueMedium};
          `}
        >
          <Text as="span" color="inherit" size="small" weight="semibold">
            {title}
          </Text>
          {titleIcon && <Icon color="inherit" size="16px" source={titleIcon} />}
        </Flex>
        {description && (
          <Text as="p" color="steelDarker" size="xsmall" weight="regular">
            {description}
          </Text>
        )}
      </div>
    </Flex>
  );

  const commonProps = {
    className,
    css: [
      getMenuItemStyle(active, showHighlightStroke),
      menuItemPaddingStyle(compact),
    ],
    onClick: handleClick,
    onFocus: onHover,
    onKeyDown,
    onMouseLeave,
    onMouseOver: onHover,
    onTouchStart,
    onTouchEnd,
    role: "listitem",
  };

  if (link) {
    return (
      <Anchor
        {...commonProps}
        href={link}
        color="inherit"
        ref={ref as React.Ref<HTMLAnchorElement>}
      >
        {content}
      </Anchor>
    );
  }

  return (
    <div {...commonProps} ref={ref as React.Ref<HTMLDivElement>} tabIndex={0}>
      {content}
    </div>
  );
};

export default forwardRef(MenuListItem);
