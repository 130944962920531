import { kebabCase } from "lodash-es";
import type { Property } from "csstype";
import { AriaRole, useEffect, useState } from "react";
import {
  mediaDesktop,
  mediaDesktopBig,
  mediaLaptop,
  mediaPhoneOnly,
  mediaTabletLandscape,
  mediaTabletPortrait,
} from "@10xdev/design-tokens";

export const useMediaQuery = (query: string): boolean => {
  // Check if window object is defined to avoid issues during SSR
  const isClient = typeof window !== "undefined";

  const [isMatch, setIsMatch] = useState(
    isClient ? window.matchMedia(query).matches : false,
  );

  useEffect(() => {
    if (!isClient) {
      return;
    }
    const mediaQueryList = window.matchMedia(query);
    const documentChangeHandler = () => setIsMatch(mediaQueryList.matches);
    mediaQueryList.addEventListener("change", documentChangeHandler);
    return () => {
      mediaQueryList.removeEventListener("change", documentChangeHandler);
    };
  }, [query, isClient]);

  return isMatch;
};

export type WithResponsive<T> =
  | T
  | {
      base?: T;
      desktop?: T;
      desktopBig?: T;
      laptop?: T;
      phoneOnly?: T;
      tabletLandscape?: T;
      tabletPortrait?: T;
    };

interface ResponsiveStyles<T> {
  [k: string]: WithResponsive<T> | undefined;
}

export function generateResponsiveCSS<T>(styles: ResponsiveStyles<T>) {
  const reducedStyles = Object.entries(styles).reduce<
    Record<string, Record<string, T>>
  >((accumulator, [key, responsiveStyles]) => {
    if (responsiveStyles) {
      if (typeof responsiveStyles === "string") {
        if (!accumulator["base"]) {
          accumulator["base"] = {};
        }
        accumulator["base"][key] = responsiveStyles;
      } else {
        for (const [breakpoint, value] of Object.entries(responsiveStyles)) {
          if (!accumulator[breakpoint]) {
            accumulator[breakpoint] = {};
          }
          accumulator[breakpoint][key] = value;
        }
      }
    }
    return accumulator;
  }, {});

  function convertStylesToString<T>(styles: Record<string, T>) {
    if (styles) {
      return `${Object.entries(styles)
        .map<string>(([style, value]) => {
          return `${kebabCase(style)}: ${value}`;
        })
        .join(";")};`;
    }
    return "";
  }

  function toMediaQueryString(breakpoint: string, cssString: string) {
    const breakpointValues = new Map<string, string>(
      Object.entries({
        desktop: mediaDesktop,
        desktopBig: mediaDesktopBig,
        laptop: mediaLaptop,
        phoneOnly: mediaPhoneOnly,
        tabletLandscape: mediaTabletLandscape,
        tabletPortrait: mediaTabletPortrait,
      }),
    );
    const target = breakpoint === "phoneOnly" ? "max-width" : "min-width";
    return `@media (${target}: ${breakpointValues.get(
      breakpoint,
    )}) { ${cssString} }`;
  }

  return `${Object.entries(reducedStyles)
    .map(([key, value]) => {
      if (key === "base") {
        return convertStylesToString(value);
      }

      return toMediaQueryString(key, convertStylesToString(value));
    })
    .join("")}`;
}

export interface BaseLayoutProps {
  backgroundColor?: WithResponsive<Property.BackgroundColor>;
  border?: WithResponsive<Property.Border>;
  borderRadius?: WithResponsive<Property.BorderRadius>;
  display?: WithResponsive<Property.Display>;
  height?: WithResponsive<Property.Height>;
  id?: string;
  margin?: WithResponsive<Property.Margin>;
  maxHeight?: WithResponsive<Property.MaxHeight>;
  maxWidth?: WithResponsive<Property.MaxWidth>;
  minHeight?: WithResponsive<Property.MinHeight>;
  minWidth?: WithResponsive<Property.MinWidth>;
  overflow?: WithResponsive<Property.Overflow>;
  padding?: WithResponsive<Property.Padding>;
  role?: AriaRole;
  whiteSpace?: WithResponsive<Property.WhiteSpace>;
  width?: WithResponsive<Property.Width>;
}

export const baseStylePropKeys: (keyof BaseLayoutProps)[] = [
  "backgroundColor",
  "border",
  "borderRadius",
  "display",
  "height",
  "margin",
  "maxHeight",
  "maxWidth",
  "minHeight",
  "minWidth",
  "overflow",
  "padding",
  "whiteSpace",
  "width",
];

export function separateStyleProps<T extends Record<string, unknown>>(
  props: T,
  propKeys: string[] = baseStylePropKeys,
) {
  const styles: Partial<T> = {};
  const rest: Record<string, unknown> = {};

  for (const key in props) {
    if (propKeys.includes(key)) {
      styles[key] = props[key];
    } else {
      rest[key] = props[key];
    }
  }

  return { styles, rest };
}
