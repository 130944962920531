import { NavigationType } from "../types";

export const createJPNavigationData = (basePath: string): NavigationType => ({
  name: "Site nav",
  menu: [
    {
      type: "productMenu",
      label: "製品",
      items: [
        {
          id: "chromium-single-cell",
          title: "Chromiumシングルセル",
          description:
            "新鮮、凍結、FFPE組織の遺伝子発現プロファイリング、マルチオームのオプションあり",
          panel: {
            type: "links",
            arrowPosition: "48px",
            rows: 3,
            links: [
              {
                id: "chromium-portfolio-overview",
                link: `${basePath}/platforms/chromium`,
                title: "ポートフォリオの概要",
                description: "Chromiumシングルセルを始めよう",
              },
              {
                id: "chromium-technology",
                link: `${basePath}/platforms/chromium/technology`,
                title: "テクノロジー",
                description:
                  "正確で信頼性の高いコアとなるシングルセル技術を探索",
              },
              {
                id: "chromium-products",
                link: `${basePath}/platforms/chromium/product-family`,
                title: "製品",
                description: "研究ニーズに合ったシングルセル製品を検索",
              },
              {
                id: "chromium-applications",
                link: `${basePath}/platforms/chromium/applications`,
                title: "アプリケーション",
                description:
                  "新技術とマルチオミックス情報で多岐にわたる問題に取り組む",
              },
              {
                id: "chromium-x-series-instruments",
                link: `${basePath}/instruments/chromium-family`,
                title: "Chromium Xシリーズの装置",
                description:
                  "10x Genomicsの装置の能力と、自動分画化の利点について",
              },
              {
                id: "chromium-support-documentation",
                link: `${basePath}/support`,
                title: "サポート資料",
                description:
                  "ユーザーガイド、プロトコル、チュートリアルなどの総合的なリソースをご紹介",
              },
            ],
            image: {
              src: "https://cdn.10xgenomics.com/image/upload/dpr_2.0,f_auto,q_auto/v1727391195/navigation/Chromium_background.png",
              height: 126,
              width: 671,
            },
          },
        },
        {
          id: "visium-spatial",
          title: "Visium Spatial",
          description: "組織の全トランスクリプトーム解析を実施",
          panel: {
            type: "links",
            arrowPosition: "148px",
            rows: 2,
            links: [
              {
                id: "visium-portfolio-overview",
                link: `${basePath}/platforms/visium`,
                title: "ポートフォリオの概要",
                description: "Visium空間解析を始めよう",
              },
              {
                id: "visium-products",
                link: `${basePath}/platforms/visium#assays`,
                title: "製品",
                description: "研究ニーズに合ったVisium製品を選択",
              },
              {
                id: "visium-instruments",
                link: `${basePath}/instruments/visium-cytassist`,
                title: "装置",
                description:
                  "Visium CytAssist装置が簡素化するワークフローと対応サンプルを確認",
              },
              {
                id: "visium-support-documentation",
                link: `${basePath}/support`,
                title: "サポート資料",
                description:
                  "ユーザーガイド、プロトコル、チュートリアルなどの総合的なリソースをご紹介",
              },
            ],
            image: {
              src: "https://cdn.10xgenomics.com/image/upload/dpr_2.0,f_auto,q_auto/v1727391196/navigation/Visium_background.png",
              height: 200,
              width: 671,
            },
          },
        },
        {
          id: "xenium-in-situ",
          title: "Xenium In Situ",
          description:
            "シングルセル空間イメージングにより組織切片中の最大5000のRNAターゲットをプロファイリング",
          panel: {
            type: "links",
            arrowPosition: "246px",
            rows: 2,
            links: [
              {
                id: "xenium-portfolio-overview",
                link: `${basePath}/platforms/xenium`,
                title: "ポートフォリオの概要",
                description: "Xenium In Situ解析を始めよう",
              },
              {
                id: "xenium-gene-expression-panels",
                link: `${basePath}/products/xenium-panels`,
                title: "Xenium遺伝子発現パネル",
                description: "研究ニーズに合ったXeniumパネルを選択",
              },
              {
                id: "xenium-instruments",
                link: `${basePath}/instruments/xenium-analyzer`,
                title: "装置",
                description:
                  "高い解像度イメージングからオンボードデータ解析までXenium Analyzerを学ぶ",
              },
              {
                id: "xenium-support-documentation",
                link: `${basePath}/support/in-situ-gene-expression`,
                title: "サポート資料",
                description:
                  "ユーザーガイド、プロトコル、チュートリアルなどの総合的なリソースをご紹介",
              },
            ],
            image: {
              src: "https://cdn.10xgenomics.com/image/upload/dpr_2.0,f_auto,q_auto/v1727391196/navigation/Xenium_background.png",
              height: 200,
              width: 671,
            },
          },
        },
        {
          id: "compare-products",
          panel: {
            type: "info",
            arrowPosition: "405px",
            bulletedList: [
              "対応する生物種およびサンプル",
              "解像度",
              "オミックス技術",
              "マルチプレックスのオプション",
              "スループット",
              "互換性のある装置",
            ],
            image: {
              src: "https://cdn.10xgenomics.com/image/upload/dpr_2.0,f_auto,q_auto/v1727391195/navigation/compare_products_background.png",
              height: 200,
              width: 615,
            },
            title: "10x Genomicsのすべてのアッセイの比較",
          },
          link: `${basePath}/products`,
          stickAtBottom: true,
          title: "製品を比較",
        },
      ],
    },
    {
      type: "resourceMenu",
      label: "関連資料",
      items: [
        {
          description: "10x Genomics製品による査読済みの論文を検索",
          icon: "publications",
          id: "publications",
          link: `${basePath}/publications`,
          title: "論文",
        },
        {
          description:
            "10x Genomics製品を使った公開されている多様なデータセットにアクセス",
          icon: "datasets",
          id: "datasets",
          link: `${basePath}/datasets`,
          title: "データセット",
        },
        {
          description:
            "全製品のユーザーガイド、チュートリアル、プロトコールにアクセス",
          icon: "documentation",
          id: "support-documentation",
          link: `${basePath}/support`,
          title: "サポート資料",
        },
        {
          description:
            "10xを超えたソフトウエア解析イントロダクションおよびチュートリアルを読む",
          icon: "analysis-guides-computer",
          id: "analysis-guides",
          link: `${basePath}/analysis-guides`,
          title: "解析ガイド",
        },
        {
          id: "blog",
          link: `${basePath}/blog`,
          title: "ブログ",
        },
        {
          id: "videos",
          link: `${basePath}/videos`,
          title: "ビデオ",
        },
        {
          id: "library",
          link: `${basePath}/library`,
          title: "ライブラリー",
        },
        {
          id: "software",
          link: `${basePath}/software`,
          title: "ソフトウェア",
        },
        {
          id: "service-providers",
          link: `${basePath}/service-providers`,
          title: "受託サービス",
        },
        {
          id: "events",
          link: `${basePath}/events`,
          title: "イベント",
        },
      ],
    },
    {
      type: "link",
      label: "サポート",
      url: `${basePath}/support`,
    },
    {
      type: "companyMenu",
      label: "会社概要",
      items: [
        {
          id: "about",
          link: `${basePath}/company`,
          title: "10x Genomics について",
        },
        {
          id: "team-and-board",
          link: `${basePath}/company#executive-team`,
          title: "経営陣および取締役",
        },
        {
          id: "careers",
          link: "https://careers.10xgenomics.com/",
          title: "採用",
        },
        {
          id: "investors",
          link: "https://investors.10xgenomics.com/",
          title: "投資家",
        },
        {
          id: "news",
          link: `${basePath}/news`,
          title: "ニュース",
        },
        {
          id: "distributors",
          link: `${basePath}/distributors`,
          title: "販売代理店",
        },
        {
          id: "contact-us",
          link: `${basePath}/contact`,
          title: "お問い合わせ",
        },
      ],
    },
  ],
  additionalLinks: [
    {
      label: "Store",
      url: `${basePath}/store`,
      target: "_self",
      locale: false,
    },
    {
      label: "Search",
      url: `${basePath}/search`,
      target: "_self",
      hasIcon: true,
    },
  ],
});
