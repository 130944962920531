import { useRef, useState } from "react";
import AdditionalLinks from "./AdditionalLinks";
import NavigationList from "./NavigationList";
import { Mode, NavigationType } from "../types";
import {
  colorSteelDarkest,
  colorWhite,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";

interface DesktopNavigationProps {
  data: NavigationType;
  mode?: Mode;
}

const MENU_TARGET_ID = "navMenu";

const DesktopNavigation = ({
  data,
  mode = "light",
}: DesktopNavigationProps) => {
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const menuRefs = useRef<(HTMLElement | null)[]>([]);

  const color = mode === "dark" ? colorWhite : colorSteelDarkest;
  const textColor = mode === "dark" ? "white" : "base";

  const getNavItemStyle = (isActive: boolean) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    padding: 0.5rem;
    position: relative;
    outline: none;

    &:focus-visible {
      outline: none;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 2px;
      background-color: ${color};
      transform: scaleX(0);
      transition: transform 0.1s ease;
    }

    &:hover:after,
    &:focus:after {
      transform: scaleX(1);
    }

    ${isActive &&
    css`
      &:after {
        transform: scaleX(1);
      }
    `}
  `;

  return (
    <nav
      aria-label="site navigation"
      css={css`
        display: none;
        z-index: 100;
        width: 100%;
        @media (min-width: ${mediaTabletLandscape}) {
          display: block;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <NavigationList
          data={data.menu}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          textColor={textColor}
          getNavItemStyle={getNavItemStyle}
          menuRefs={menuRefs}
        />

        <AdditionalLinks
          links={data.additionalLinks}
          mode={mode}
          menuRefs={menuRefs}
          menuLength={data?.menu?.length ?? 0}
        />
      </div>
      <div id={MENU_TARGET_ID} />
    </nav>
  );
};

export default DesktopNavigation;
